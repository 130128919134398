import React from "react"
import { Button, Card, Image, Modal, Icon } from "semantic-ui-react"

const WorkCardWithModal = ({ project }) => {
    return project?.title ? (<Modal
        centered
        size="small"
        closeOnPortalMouseLeave
        trigger={
            <Card
                style={{ textAlign: "left" }}>
                <img src={project.image}
                    style={{ height: "150px" }} />
                <Card.Content>
                    <Card.Header style={{
                        textAlign: "left", fontSize: "14px", textOverflow: "ellipsis",
                        overflow: "hidden", whiteSpace: "nowrap"
                    }}>
                        {project.platform}
                        {project.title}
                    </Card.Header>
                </Card.Content>
            </Card>}>
        <Modal.Header >
            {project.title}
        </Modal.Header>
        <Modal.Content image>
            <Image src={project.image} wrapped />
            <Modal.Description>
                <p style={{ fontSize: "16px" }} textAlign="left">
                    {project.description}
                </p>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button
                icon
                color='green'
                labelPosition="left"
                as="a"
                href={project.url}
                target="_blank"
            >
                <Icon name={project.platform_icon} />
                Check it out</Button>
        </Modal.Actions>
    </Modal>) : null;
}

export default WorkCardWithModal
